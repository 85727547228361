import React, { useContext, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
// constants
import { ERROR_MESSAGES } from '@src/constants';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
import { ADD_TO_BASKET_OFFER_LABEL } from '../account-result/constants';
import { OUT_OF_STOCK } from '@src/constants/messages';
// context
import { GlobalContext } from '@store/global-state';
// components
import SubmitButton from '../submit-button';
import Loader from '../../components/loader';
// queries
import { BASKET_ADD_ITEM } from '@queries/basket';
// services
import { setCookie, getCookie } from '@services/cookies';
import { formatGraphQLError, formatPrice } from '@services/format';
import couponCodeService from '@src/utils/couponCodeService';
//hooks
import { updateCachedBasket } from '@hooks/useBasket';
import { useAddToBasket } from '@src/features/shop/hooks/useAddToBasket';
import { useQueryParamBoolean } from '@hooks/useQueryParams';
import { useFreeTrial } from '@hooks/useFreeTrial';
// styles
import styles from './add-to-basket.module.scss';

interface IProps {
  label?: string;
  isSmallAddToBasketButton?: boolean;
  isReorderButton?: boolean;
  sku?: string;
  plans?: any;
  isFreeTrial?: boolean;
  showPlanLabel?: boolean;
  productType?: string;
  showButtonOnly?: boolean;
  showDeliveryText?: boolean;
  displaySinglePlanLabel?: boolean;
  daysOffer?: boolean;
  isBundle?: boolean;
  ctaText?: string;
  isAvailable?: boolean;
  dontShowDeliveryCost?: boolean;
  basketCouponCode?: string;
  fullWidth?: boolean;
}

const AddToBasket: React.FC<IProps> = ({
  label = '',
  isSmallAddToBasketButton = false,
  isReorderButton = false,
  sku,
  plans,
  isFreeTrial = false,
  showPlanLabel = false,
  showDeliveryText = true,
  showButtonOnly = false,
  displaySinglePlanLabel = false,
  daysOffer = false,
  isBundle = false,
  ctaText,
  isAvailable = true,
  dontShowDeliveryCost,
  basketCouponCode,
  fullWidth = false,
}: IProps) => {
  if (!isAvailable) {
    return <h4>{OUT_OF_STOCK}</h4>;
  }

  const hasRouteOneOff = useQueryParamBoolean('oneOff');
  const { isFreeTrialUsed } = useFreeTrial();
  const canUseFreeTrial = useMemo(() => isFreeTrial && !isFreeTrialUsed, [
    isFreeTrial,
    isFreeTrialUsed,
  ]);

  const {
    getOffer,
    setOffer,
    getPPOfferTypeFromValue,
    getPPCouponCodeFromValue,
  } = couponCodeService();

  const { setShowBasket, currencyCode, setErrorModalMessage, setMessageModal } = useContext(
    GlobalContext
  );

  const onAddToBasketComplete = () => {
    setShowBasket(true);
  };

  const [
    redeemFreeTrial,
    { loading: redeemFreeTrialLoading, error: redeemFreeTrialError },
  ] = useMutation(BASKET_ADD_ITEM, {
    update: (cache, response) => {
      if (!response.data) return;
      updateCachedBasket(response.data.basket_addItem);
    },
    onCompleted: redeemFreeTrialData => {
      setCookie(
        COOKIES.basketId,
        redeemFreeTrialData.basket_addItem.basketId,
        COOKIE_EXPIRES_IN.thirtyDays
      );
      setShowBasket(true);
    },
    onError: error => {
      error.message
        ? setErrorModalMessage(formatGraphQLError(error.message))
        : setErrorModalMessage(ERROR_MESSAGES.generic);
    },
  });

  const { addItemToBasket, loading: addItemToBasketLoading } = useAddToBasket(
    onAddToBasketComplete
  );
  const defaultPlan = hasRouteOneOff
    ? plans?.find((plan: any) => plan.sku.includes('one-time'))
    : plans?.find((plan: any) => plan.default);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      sku: plans ? defaultPlan?.sku || plans[0]?.sku : sku,
    },
  });

  const buttonLabel = useMemo(() => {
    if (ctaText) {
      return ctaText;
    }
    return label ? label : canUseFreeTrial ? 'Claim free trial' : 'Add to basket';
  }, [label, canUseFreeTrial, ctaText]);

  const isThereA4PPOffer = () => {
    // Check if there is a 4PP offer applied
    const offer = getOffer('4pp');
    if (ctaText === ADD_TO_BASKET_OFFER_LABEL && offer) {
      return true;
    }
  };

  const add4PPCouponCodeIfNeeded = () => {
    // On add to basket we remvoe coupon code, but if we delete product and get back to result page we still
    // visual offer label, so we need to add coupon code back in this case
    // Both coupon will be removed on confirmation page
    const offer = getOffer('4pp');
    const offerType = getPPOfferTypeFromValue(offer);
    const cookieValue = getPPCouponCodeFromValue(offerType);

    if (basketCouponCode && basketCouponCode !== cookieValue) {
      // If there us a 4pp offer and already added different coupun code to basket we need to show a message
      setShowBasket(true);
      setMessageModal({
        visible: true,
        title: 'Offer already applied',
        message: 'Please remove your existing offer from the basket to apply this one',
        buttonLabel: 'OK',
      });
      return true;
    }

    // If there is a 4pp offer and no coupon code added we need to add it
    setOffer('4pp', offerType);
  };

  const handleAddItemToBasket = (sku: string) => {
    const basketId = getCookie(COOKIES.basketId);

    if (canUseFreeTrial) {
      redeemFreeTrial({
        variables: {
          basketId: '',
          partnership: '',
          campaign: '',
          sku: sku,
          freeTrial: true,
          quantity: 1,
        },
      });
      return;
    }

    const isThereA4PPOfferApplied = isThereA4PPOffer();
    // Check if there is a 4PP offer applied
    if (isThereA4PPOfferApplied) {
      // If there us a 4pp offer applied and already added coupun code we need to block adding to basket
      // If not we should go to addItemToBasket
      const blockAdding = add4PPCouponCodeIfNeeded();
      if (blockAdding) return;
    }

    addItemToBasket({
      variables: {
        basketId,
        sku,
        quantity: 1,
      },
    });
  };

  const handleOptionSubmit = (formData: any) => {
    const sku = formData['sku'];

    handleAddItemToBasket(sku);
  };

  const getFormattedPrice = (plan: any) =>
    formatPrice(plan.offerPrice || plan.rrpPrice, plan.currency);

  const addToBasketButtonClass = () => {
    if (isSmallAddToBasketButton) return styles.buttonPrimarySmall;
    if (showButtonOnly) return styles.containerWithoutMargin;
    if (isReorderButton) return styles.reorderButton;
    return styles.container;
  };

  const deliveryCost = () => {
    if (dontShowDeliveryCost) return 'Free UK delivery';
    return <p className={styles.small}>Free UK delivery on subscriptions &amp; orders over £20</p>;
  };

  return (
    <div className={addToBasketButtonClass()}>
      {daysOffer && (
        <p className={styles.daysOffer} data-testid="add-to-basket-offer-price">
          BUY TODAY & SAVE |{' '}
          <span>
            <del>{formatPrice(plans[0]?.rrpPrice, plans[0]?.currency)}</del>
          </span>{' '}
          {formatPrice(plans[0]?.offerPrice, plans[0]?.currency)}
        </p>
      )}

      {plans?.length === 1 && !displaySinglePlanLabel && (
        <div>
          {!showButtonOnly && (
            <p className={styles.priceWrapper} data-testid="add-to-basket-price">
              {plans[0].offerPrice && (
                <span className={styles.oldPrice}>
                  <del>{formatPrice(plans[0].rrpPrice, plans[0].currency)}</del>
                </span>
              )}
              {!canUseFreeTrial && (
                <span className={styles.price}>
                  {formatPrice(plans[0].offerPrice || plans[0].rrpPrice, plans[0].currency)}
                </span>
              )}
            </p>
          )}
          <SubmitButton
            fullWidth={fullWidth}
            buttonLabel={buttonLabel}
            loading={addItemToBasketLoading || redeemFreeTrialLoading}
            onClick={() => handleAddItemToBasket(plans[0].sku)}
            error={redeemFreeTrialError}
          />
        </div>
      )}

      {(plans?.length > 1 || displaySinglePlanLabel) && (
        <form onSubmit={handleSubmit(handleOptionSubmit)}>
          <div className={styles.radioGroup}>
            {plans.map((plan: any) => (
              <label key={plan.sku} className={styles.radioContainer}>
                <input
                  type="radio"
                  name="sku"
                  value={plan.sku}
                  ref={register({ required: true })}
                />
                <span className={styles.checkmark}></span>

                <div className={styles.radioLabel}>
                  <span>{plan.planLabel}</span>
                  <span>
                    {plan.offerPrice ? (
                      <s className={styles.crossedPrice}>
                        {formatPrice(plan.rrpPrice, plan.currency)}
                      </s>
                    ) : null}
                    {getFormattedPrice(plan)}
                  </span>
                </div>
              </label>
            ))}
          </div>

          <SubmitButton
            fullWidth={fullWidth}
            loading={addItemToBasketLoading}
            buttonLabel={buttonLabel}
          />
        </form>
      )}

      {!showButtonOnly && showDeliveryText && !isReorderButton && (
        <div className={styles.delivery}>
          {showPlanLabel && <p className={styles.small}>{plans[0].planLabel}</p>}
          {currencyCode === 'GBP' &&
            (!canUseFreeTrial ? (
              deliveryCost()
            ) : (
              <p className={styles.small}>
                £4.95 P&amp;P
                <br />
                £39.95/month after free trial with free delivery.
                <br />
                Pause or cancel anytime
              </p>
            ))}
        </div>
      )}

      {isReorderButton && sku && (
        <>
          {addItemToBasketLoading && <Loader />}
          <div onClick={() => handleAddItemToBasket(sku)}>Reorder</div>
        </>
      )}

      {isSmallAddToBasketButton && sku && showButtonOnly && (
        <>
          {addItemToBasketLoading && <Loader />}
          <div onClick={() => handleAddItemToBasket(sku)}>{buttonLabel}</div>
        </>
      )}

      {isBundle && sku && (
        <SubmitButton
          fullWidth={fullWidth}
          buttonLabel={buttonLabel}
          loading={addItemToBasketLoading}
          onClick={() => handleAddItemToBasket(sku)}
        />
      )}
    </div>
  );
};

export default AddToBasket;
